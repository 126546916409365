import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Alert,
    AlertIcon,
    AlertDescription,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { fetchLabLocationsOutside, fetchFacilitiesBySearch, getAllFacilitiesOutside, fetchAllRoutes, createRoutePickup } from '../../api'; // Add necessary API functions
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

const dayOptions = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' }
];

const CreatePickupModal = ({ isOpen, onClose, isRoutePickup = false }) => {
    const [pickupType, setPickupType] = useState('Default');
    const [pickupLocation, setPickupLocation] = useState('');
    const [dropOffLocation, setDropOffLocation] = useState('');
    const [route, setRoute] = useState('');
    const [days, setDays] = useState([]); // Set as an array
    const [pickupOrder, setPickupOrder] = useState(1); // Default to 1 for all orders
    const [note, setNote] = useState('');
    const [facilities, setFacilities] = useState([]);
    const [labLocations, setLabLocations] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorModal, setErrorModal] = useState(null);

    useEffect(() => {
        getFacilities();
        getRoutes();
        getLabLocations();
    }, []);

    const getFacilities = async () => {
        try {
            const response = await getAllFacilitiesOutside();
            if (response.success) {
                setFacilities(response.data.facilities);
            }
        } catch (error) {
            setErrorModal('Error loading facilities');
        }
    };

    const getLabLocations = async () => {
        try {
            const response = await fetchLabLocationsOutside();
            if (response.success) {
                setLabLocations(response.data);
            }
        } catch (error) {
            setErrorModal('Error loading lab locations');
        }
    };

    const getRoutes = async () => {
        try {
            const response = await fetchAllRoutes();
            if (response.success) {
                setRoutes(response.data.routes);
            }
        } catch (error) {
            setErrorModal('Error loading routes');
        }
    };

    const handleCreatePickup = async () => {
        setErrorModal(null);

        // Validate fields
        if ((pickupType !== 'Supplies' && pickupType !== 'DropOffOnly' && !pickupLocation) || !route || days.length === 0) {
            setErrorModal('Please fill in all required fields.');
            return;
        }

        setLoading(true);
        try {
            const response = await createRoutePickup({
                type: pickupType,
                pickupLocation,
                dropOffLocation,
                routeId: route,
                pickupDays: days.map(day => ({ day: day.value, pickupOrder: pickupOrder })), // Map the selected days and set order to 1
                note,
                isRoutePickup
            });

            if (response.success) {
                onClose();
            } else {
                setErrorModal(response.error);
            }
        } catch (error) {
            setErrorModal('Error creating pickup');
        }
        setLoading(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Pickup</ModalHeader>
                <ModalBody>
                    {errorModal && <Alert status="error"><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}

                    <FormControl mb={3}>
                        <FormLabel>Pickup Type</FormLabel>
                        <Select
                            options={[
                                { value: 'Default', label: 'Default' },
                                { value: 'Supplies', label: 'Supplies' },
                                { value: 'DropOffOnly', label: 'Drop-Off Only' }
                            ]}
                            value={{ value: pickupType, label: pickupType }}
                            onChange={(option) => setPickupType(option.value)}
                        />
                    </FormControl>

                    <>
                        <FormControl mb={3} isRequired>
                            <FormLabel>{pickupType != 'Default' ? "Drop Location" : "Pickup location"}</FormLabel>
                            {/* <AsyncSelect
                                cacheOptions
                                loadOptions={fetchFacilitiesBySearch}
                                defaultOptions
                                onChange={(option) => setPickupLocation(option ? option.value : '')}
                                value={pickupLocation
                                    ? { value: pickupLocation, label: facilities.find(fac => fac._id === pickupLocation)?.name }
                                    : null}
                                placeholder="Search and select a facility"
                                closeMenuOnSelect={true}
                                isClearable
                            /> */}

                            <Select
                                options={facilities.map(fac => ({
                                    value: fac._id,
                                    label: `${fac.name} - ${fac.address.city}`
                                }))}
                                onChange={(option) => setPickupLocation(option ? option.value : '')}
                                value={
                                    pickupLocation
                                        ? { value: pickupLocation, label: facilities.find(f => f._id === pickupLocation)?.name }
                                        : null
                                }
                                placeholder="Select a facility"
                                isClearable
                            />


                        </FormControl>
                    </>

                    <FormControl mb={3} isRequired>
                        <FormLabel>{pickupType != 'Default' ? "Pickup Location" : "Drop off Location"}</FormLabel>
                        <Select
                            options={labLocations.map(lab => ({ value: lab._id, label: lab.name }))}
                            value={labLocations.find(lab => lab._id === dropOffLocation) ? { value: dropOffLocation, label: labLocations.find(lab => lab._id === dropOffLocation)?.name } : null}
                            onChange={(option) => setDropOffLocation(option.value)}
                        />
                    </FormControl>

                    <FormControl mb={3} isRequired>
                        <FormLabel>Route</FormLabel>
                        <Select
                            options={routes.map(route => ({ value: route._id, label: route.name }))}
                            value={routes.find(r => r._id === route) ? { value: route, label: routes.find(r => r._id === route)?.name } : null}
                            onChange={(option) => setRoute(option.value)}
                        />
                    </FormControl>

                    <FormControl mb={3} isRequired>
                        <FormLabel>Days</FormLabel>
                        <Select
                            isMulti
                            options={dayOptions}
                            value={days}
                            onChange={(selectedOptions) => setDays(selectedOptions || [])} // Update days
                            closeMenuOnSelect={false}
                        />
                    </FormControl>

                    <FormControl style={{ display: 'none' }} mb={3} isRequired>
                        <FormLabel>Pickup Order</FormLabel>
                        <Input
                            type="number"
                            value={pickupOrder}
                            onChange={(e) => setPickupOrder(e.target.value)}
                            placeholder="Set pickup order (default is 1)"
                        />
                    </FormControl>

                    <FormControl mb={3}>
                        <FormLabel>Note</FormLabel>
                        <Textarea
                            placeholder="Add any notes"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose} mr={3}>Close</Button>
                    <Button isLoading={loading} colorScheme="blue" onClick={handleCreatePickup}>Submit</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CreatePickupModal;
