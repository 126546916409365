import { useState, useEffect } from 'react';
import {
    Box, VStack, Button, HStack, Table, Thead, Tbody, Tr, Th, Td, Input,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter,
    useDisclosure, Text,
    Badge
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import { fetchTransactions } from '../../api';
import TitleHeader from '../../components/TitleHeader';

const TransactionsPage = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();

    const [transactions, setTransactions] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [selected, setSelected] = useState(null);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [totalPaid, setTotalPaid] = useState(0);

    const itemsPerPage = 100;

    const loadTransactions = async () => {
        setLoading(true);
        const res = await fetchTransactions(currentPage, itemsPerPage, from, to);
        if (res.success) {
            setTransactions(res.data.transactions);
            setTotalPaid(res.data.totalPaid);
        }
        setLoading(false);
    };

    useEffect(() => { loadTransactions(); }, []);
    useEffect(() => {
        const filteredTx = transactions.filter(t =>
            t.name?.toLowerCase().includes(search.toLowerCase()) ||
            t.email?.toLowerCase().includes(search.toLowerCase()) ||
            t.claimNumber?.toLowerCase().includes(search.toLowerCase())
        );
        setFiltered(filteredTx);
        setCurrentPage(1);
    }, [search, transactions]);

    const currentItems = filtered.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const totalPages = Math.ceil(filtered.length / itemsPerPage);

    const openView = (tx) => {
        setSelected(tx);
        onOpen();
    };

    return (
        <Box maxW="full" mx="auto" p="8">
            <VStack spacing={6} align="stretch">
                <TitleHeader title="Transactions" buttonText="Create Transaction" onClick={onCreateOpen} />
                <Input placeholder="Search by name/email/claim #" value={search} onChange={(e) => setSearch(e.target.value)} />

                <HStack spacing={4} mb={4}>
                    <Input type="date" value={from} onChange={(e) => setFrom(e.target.value)} />
                    <Input type="date" value={to} onChange={(e) => setTo(e.target.value)} />
                    <Button onClick={loadTransactions}>Apply Filter</Button>
                </HStack>

                <Text fontWeight="bold">Total Paid: ${totalPaid.toFixed(2)}</Text>

                <Table variant="simple" size="sm">
                    <Thead>
                        <Tr>
                            <Th>#</Th>
                            <Th>Name</Th>
                            <Th>Claim #</Th>
                            <Th>Status</Th>
                            <Th>Total</Th>
                            <Th>Type</Th>
                            <Th>Paid At</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentItems.map((tx, ind) => (
                            <Tr key={tx._id}>
                                <Td>{ind + 1}</Td>
                                <Td>{tx.name}</Td>
                                <Td>{tx.claimNumber}</Td>
                                <Td> <Badge colorScheme={tx.status == 'Success' ? 'green' : 'red'}>{tx.status}</Badge></Td>
                                <Td>${tx.totalAmount?.toFixed(2)}</Td>
                                <Td>
                                    {tx.upcomingCharges?.length > 0
                                        ? `Installment (${tx.upcomingCharges.length})`
                                        : 'Full Payment'}
                                </Td>
                                <Td>{new Date(tx.createdAt).toLocaleString()}</Td>
                                <Td>
                                    <Button size="xs" onClick={() => openView(tx)} leftIcon={<ViewIcon />}>View</Button>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>

                <HStack justify="center">
                    {Array.from({ length: totalPages }, (_, i) => (
                        <Button key={i} size="sm" onClick={() => setCurrentPage(i + 1)} colorScheme={currentPage === i + 1 ? "teal" : "gray"}>
                            {i + 1}
                        </Button>
                    ))}
                </HStack>

                {/* View Modal */}
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Transaction Details</ModalHeader>
                        <ModalBody>
                            {selected && (
                                <VStack align="start" spacing={3}>
                                    <Text><strong>Service Address:</strong> {selected.serviceAddress}</Text>
                                    <Text><strong>City:</strong> {selected.serviceCity}</Text>
                                    <Text><strong>State:</strong> {selected.serviceState}</Text>
                                    <Text><strong>Zipcode:</strong> {selected.serviceZipcode}</Text>
                                    <Text><strong>Country:</strong> {selected.serviceCountry}</Text>
                                    <Text><strong>Card Last 4:</strong> {selected.cardLast4}</Text>
                                    <Text><strong>Initials:</strong> {selected.initials}</Text>
                                    <Text><strong>Payment Method:</strong> {selected.paymentMethod}</Text>
                                    <Text><strong>Transaction ID:</strong> {selected.transactionId}</Text>
                                    <Text><strong>Created At:</strong> {new Date(selected.createdAt).toLocaleString()}</Text>

                                    {selected.upcomingCharges?.length > 0 && (
                                        <>
                                            <Text mt={4} fontWeight="bold">Upcoming Charges:</Text>
                                            {selected.upcomingCharges.map((charge, i) => (
                                                <Box key={charge._id || i} pl={4} borderLeft="2px solid #ccc" ml={2}>
                                                    <Text><strong>Amount:</strong> ${charge.amount}</Text>
                                                    <Text><strong>Date:</strong> {new Date(charge.scheduledDate).toLocaleDateString()}</Text>
                                                    <Text><strong>Status:</strong> {charge.status}</Text>
                                                </Box>
                                            ))}
                                        </>
                                    )}
                                </VStack>
                            )}
                        </ModalBody>
                        <ModalFooter><Button onClick={onClose}>Close</Button></ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Create Modal (Basic) */}
                <Modal isOpen={isCreateOpen} onClose={onCreateClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Create Transaction</ModalHeader>
                        <ModalBody>
                            <Text>Create form goes here</Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={onCreateClose}>Cancel</Button>
                            <Button colorScheme="blue" ml={3}>Save</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </VStack>
        </Box>
    );
};

export default TransactionsPage;
