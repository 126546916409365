import { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Button,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    FormControl,
    FormLabel,
    Center,
    Badge,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    useToast,
    Divider,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import Pagination from '../../components/Pagination';
import TitleHeader from '../../components/TitleHeader';
import Loading from '../../components/Loading/Loading';
import { deleteRoutePickupCompletely, fetchAllRoutesPickups, fetchFacilitiesBySearch, fetchLabLocationsOutside, fetchRouteBySearch, searchRoutePickups } from '../../api';
import AccessDenied from '../../components/AccessDenied';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { hasPermission } from '../../Utils';


const dayOptions = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' }
];
const AllRoutesPickups = () => {
    const navigate = useNavigate();
    const { isOpen: isDeleteModalOpen, onOpen: onOpenDeleteModal, onClose: onCloseDeleteModal } = useDisclosure();
    const toast = useToast()
    const [routesPickups, setRoutesPickups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingProcess, setLoadingProcess] = useState(false);
    const [selectedPickup, setSelectedPickup] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [error, setError] = useState(null);
    const itemsPerPage = 25;

    const [pickupLocation, setPickupLocation] = useState(null);
    const [dropOffLocation, setDropOffLocation] = useState(null);

    const [searching, setSearching] = useState(false);

    const [labLocations, setLabLocations] = useState([]);
    const [daysSelected, setSelectedDays] = useState([]); // Set as an array
    const [routeOption, setRouteOption] = useState([]);

    useEffect(() => {
        getRoutesPickups();
        getLabLocations();
    }, []);

    const getLabLocations = async () => {
        try {
            const response = await fetchLabLocationsOutside();
            if (response.success) {
                setLabLocations(response.data);
            }
        } catch (error) {
            console.log('Error loading lab locations');
        }
    };

    const getRoutesPickups = async (page = 1) => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetchAllRoutesPickups(page, itemsPerPage);
            console.log(response)
            if (response.success) {
                setRoutesPickups(response.data.pickups);
                setCurrentPage(response.data.currentPage);
                setTotalPages(response.data.totalPages);
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError('Failed to load routes');
        }
        setLoading(false);
    };

    useEffect(() => {
        console.log({ pickupLocation, dropOffLocation, daysSelected })
    }, [pickupLocation, dropOffLocation, daysSelected])

    const handleSearch = async (currentPage = 1) => {
        setLoading(true);
        try {
            const searchParams = {
                pickupLocationId: pickupLocation?.value || null,    // Extract value from react-select
                dropOffLocationId: dropOffLocation?.value || null,  // Extract value from react-select
                routeId: routeOption?.value || null,  // Extract value from react-select
                days: daysSelected.length > 0 ? daysSelected.map(day => day.value) : [],  // Ensure days is an array
                page: currentPage,
                limit: itemsPerPage,
            };

            console.log(searchParams)
            setSearching(true)

            const response = await searchRoutePickups(searchParams);
            if (response.success) {

                setRoutesPickups(response.data.pickups);
                setCurrentPage(response.data.currentPage);
                setTotalPages(response.data.totalPages);

            } else {
                toast({
                    title: "Oops !",
                    description: response.error,
                    status: "error",
                    duration: 4000, // duration in milliseconds
                    isClosable: true,
                });

            }
        } catch (error) {
            console.error('Error performing search:', error);
        }
        setLoading(false);
    };

    const handleDeleteRoute = async () => {
        setLoadingProcess(true);
        try {
            const response = await deleteRoutePickupCompletely(selectedPickup._id);
            if (response.success) {
                setRoutesPickups(routesPickups.filter(route => route._id !== selectedPickup._id));
                toast({
                    title: "Removed Successful",
                    description: `The pickup has been removed Completely`,
                    status: "success",
                    duration: 1000, // duration in milliseconds
                    isClosable: true,
                });

                onCloseDeleteModal();
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError('Error deleting route');
        }
        setLoadingProcess(false);
    };

    const clearSearch = () => {
        setSearching(false);
        setPickupLocation(null);
        setDropOffLocation(null);
        setSelectedDays([]);
        getRoutesPickups(); // Reloads default pickups data
    };

    const handlePageChanges = (page) => {
        if (searching) {
            handleSearch(page);
        } else {
            getRoutesPickups(page);
        }
    };

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                <TitleHeader
                    title="Routes Pickups"
                />

                <HStack p={2} mb={3} spacing={4} align="start">
                    <FormControl isRequired>
                        <FormLabel>Pickup Location</FormLabel>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={fetchFacilitiesBySearch}
                            defaultOptions
                            onChange={(option) => setPickupLocation(option)}  // Set entire option
                            value={pickupLocation || null} // Use the selected option directly
                            placeholder="Search and select a facility"
                            closeMenuOnSelect={true}
                            isClearable
                        />
                    </FormControl>

                    <FormControl isRequired>
                        <FormLabel>Route</FormLabel>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={fetchRouteBySearch}
                            defaultOptions
                            onChange={(option) => setRouteOption(option)}  // Set entire option
                            value={routeOption || null} // Use the selected option directly
                            placeholder="Search by Route"
                            closeMenuOnSelect={true}
                            isClearable
                        />
                    </FormControl>


                    <FormControl isRequired>
                        <FormLabel>Drop off Location</FormLabel>
                        <Select
                            cacheOptions
                            closeMenuOnSelect={true}
                            isClearable
                            options={labLocations.map(lab => ({ value: lab._id, label: lab.name }))}
                            value={dropOffLocation}  // Use the selected object directly
                            onChange={(option) => setDropOffLocation(option || null)}  // Store entire option or null if cleared
                        />
                    </FormControl>

                    <FormControl isRequired>
                        <FormLabel>Days</FormLabel>
                        <Select
                            isMulti
                            options={dayOptions}
                            value={daysSelected}
                            onChange={(selectedOptions) => setSelectedDays(selectedOptions || [])} // Update days
                            closeMenuOnSelect={false}
                        />
                    </FormControl>
                    <VStack>
                        <Button mt={searching ? 0 : 34} colorScheme="blue" size={"sm"} onClick={() => handleSearch(1)}>
                            Search
                        </Button>

                        {searching &&
                            <Button colorScheme="red" size={"sm"} onClick={clearSearch}>
                                Clear
                            </Button>
                        }
                    </VStack>
                </HStack>
                <Divider />

                {loading ?
                    <Center mt={"20%"}>
                        <Loading />
                    </Center>
                    :
                    <>
                        {error ? (
                            <AccessDenied msg={error} />
                        )
                            :
                            routesPickups.length > 0 ? (
                                <>
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th>#</Th>
                                                <Th>Pickup</Th>
                                                <Th>Drop</Th>
                                                <Th>Day</Th>
                                                <Th>Type</Th>
                                                <Th>Route</Th>
                                                <Th>Created At</Th>
                                                <Th textAlign="right">Actions</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {routesPickups.map((route, index) => (
                                                <Tr
                                                    color={route.type === 'Supplies' ? 'white' : 'gray.900'}
                                                    bg={
                                                        route.type === 'DropOffOnly' ? "pink.100" :
                                                            route.type === 'Supplies' ? 'purple' :
                                                                'white'
                                                    } _hover={{ bg: "gray.700", color: "white" }} key={route._id}>
                                                    <Td>{index + 1}</Td>
                                                    <Td>{route?.pickupLocation?.name || "N/A"}</Td>
                                                    <Td>{route?.dropOffLocation?.name || "N/A"}</Td>


                                                    <Td>
                                                        <Popover>
                                                            <PopoverTrigger>
                                                                <Badge cursor={'pointer'}>
                                                                    View Days
                                                                </Badge>
                                                            </PopoverTrigger>
                                                            <PopoverContent>
                                                                <PopoverArrow />
                                                                <PopoverCloseButton />
                                                                <PopoverHeader color={"black"} fontWeight="bold">Pickup Schedule</PopoverHeader>
                                                                <PopoverBody>
                                                                    <Table variant="simple" size="sm">
                                                                        <Thead>
                                                                            <Tr>
                                                                                <Th>Day</Th>
                                                                                <Th>Pickup Order</Th>
                                                                            </Tr>
                                                                        </Thead>
                                                                        <Tbody>
                                                                            {route.pickupDays.map((day) => (
                                                                                <Tr color={"black"} key={day._id}>
                                                                                    <Td>{day.day}</Td>
                                                                                    <Td>{day.pickupOrder}</Td>
                                                                                </Tr>
                                                                            ))}
                                                                        </Tbody>
                                                                    </Table>
                                                                </PopoverBody>
                                                            </PopoverContent>
                                                        </Popover>
                                                    </Td>


                                                    <Td>
                                                        <Badge colorScheme={route.type === 'DropOffOnly' ? 'pink' : route.type === 'Supplies' ? 'purple' : "orange"}>
                                                            {route.type === 'DropOffOnly' ? "Drop Off" : route.type === 'Default' ? "Pickup" : route.type}
                                                        </Badge>
                                                    </Td>




                                                    <Td>
                                                        <Badge cursor={'pointer'} onClick={() => { navigate(`/routes/view/${route.routeId._id}`) }} colorScheme={'red'}>
                                                            {route.routeId.name}
                                                        </Badge>
                                                    </Td>

                                                    <Td>{new Date(route.createdAt).toLocaleDateString()}</Td>


                                                    <Td textAlign="right">
                                                        {hasPermission('deleteRoutePickup') &&

                                                            <HStack justifyContent="flex-end" spacing={4}>
                                                                <Button onClick={() => { setSelectedPickup(route); onOpenDeleteModal(); }} size="xs" colorScheme="red">
                                                                    <DeleteIcon mr={2} /> Delete
                                                                </Button>
                                                            </HStack>
                                                        }
                                                    </Td>
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>

                                    <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChanges} />
                                </>
                            ) : (
                                <AccessDenied msg="Nothing here yet !" icon='info' />
                            )}
                        {selectedPickup &&
                            <Modal isOpen={isDeleteModalOpen} onClose={onCloseDeleteModal}>
                                <ModalOverlay />
                                <ModalContent>
                                    <ModalHeader>Confirm Deletion</ModalHeader>
                                    <ModalBody>
                                        Are you sure you want to delete this pickup ?
                                        <br /><br />
                                        <strong>{selectedPickup?.pickupLocation?.name}</strong>
                                        <br /><br />
                                        This will remove it form all day's and not just one day ?
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button isLoading={loadingProcess} colorScheme="red" onClick={handleDeleteRoute}>Delete</Button>
                                        <Button onClick={onCloseDeleteModal}>Cancel</Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Modal>
                        }
                    </>
                }



            </VStack>
        </Box >
    );
};

export default AllRoutesPickups;
