import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SidebarLayout from './template/SidebarLayout';
import ClientLayout from './template/ClientLayout';


import SignUpBusiness from './pages/Businesses/SignUpBusiness';
import AllBusinessesPage from './pages/Businesses/AllBusinessesPage';
import BusinessDetailsPage from './pages/Businesses/BusinessDetailsPage';
import UpdateBusiness from './pages/Businesses/UpdateBusiness';
import CompleteBusiness from './pages/Businesses/CompleteBusiness';

import ProfilePage from './pages/AuthAccount/ProfilePage';
import Login from './pages/AuthAccount/Login';


import SalesRepManagement from './pages/SalesReps/SalesRepManagement';
import SalesRepClients from './pages/SalesReps/SalesRepClients';

import NotFoundPage from './pages/NotFound/NotFoundPage';
import RolesManagement from './pages/Roles/RolesManagement';
import LogsPage from './pages/Logs/LogsPage';
import TrashPage from './pages/Trash/TrashPage';
import UsersList from './pages/Users/UsersList';

import DocumentsPage from './pages/Documents/DocumentsPage';


import Dashboard from './pages/Dashboard';
import PrivateRoute from './PrivateRoute';
import CategoriesManagement from './pages/ClientsSupplies/CategoriesManagement';
import SuppliesManagement from './pages/ClientsSupplies/SuppliesManagement';
import CreateOrder from './pages/ClientsSupplies/CreateOrder';
import ClientsOrders from './pages/ClientsSupplies/ClientsOrders';
import ClientsFinishedOrders from './pages/ClientsSupplies/ClientsFinishedOrders';
import OrderDetails from './pages/ClientsSupplies/OrderDetails';
import Warehouse from './pages/ClientsSupplies/Warehouse';
import AllLabLocationsPage from './pages/Labs/AllLabLocationsPage';
import FacilitiesPage from './pages/Facilities/Facilities';
import PSCLocationsPage from './pages/PscLocation/PSCLocationsPage';
import InsuranceCategories from './pages/Insurance/InsuranceCategories';
import Insurances from './pages/Insurance/Insurances';

import TestsPricesCategories from './pages/TestsPrices/TestsPricesCategories';
import Prices from './pages/TestsPrices/Prices';
import AllCouriers from './pages/Couriers/AllCouriers';
import AllRoutes from './pages/Routes/AllRoutes';
import AllRoutesPickups from './pages/Routes/AllRoutesPickups';
import ViewRoute from './pages/Routes/ViewRoute';
import AllPickups from './pages/Pickups/AllPickups';
import Warehouses from './pages/Inventory/Warehouses';
import InventoryLevels from './pages/Inventory/InventoryLevels';
import ReceiveInventory from './pages/Inventory/ReceiveInventory';
import TransactionsHistory from './pages/Inventory/TransactionsHistory';
import EmailTemplates from './pages/Emails/EmailTemplates';
import CreateTemplate from './pages/Emails/CreateTemplate';
import EditTemplate from './pages/Emails/EditTemplate';
import CreateOrderInternal from './pages/ClientsSupplies/CreateOrderInternal';
import FinishedPickups from './pages/Pickups/FinishedPickups';
import RequestPickup from './pages/Pickups/RequestPickup';
import ReceivePickups from './pages/Pickups/ReceivePickups';
import ViewPickup from './pages/Pickups/ViewPickup';
import Landing from './template/HomePage/Landing';
import PrintOrderPage from './pages/ClientsSupplies/PrintOrderPage';
import Report from './pages/Reports/Report';
import MessagesList from './pages/Messages/MessagesList';
import ShipmentsList from './pages/Shipments/ShipmentsList';
import TransactionsPage from './pages/Transactions/TransactionsPage';


function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />

        {/* Protected Routes */}
        <Route element={<PrivateRoute><SidebarLayout /></PrivateRoute>}>
          {/* Main Dashboard */}
          <Route path="/dashboard" element={<Dashboard />} />

          {/* Other Protected Routes */}
          <Route path="/signup" element={<SignUpBusiness />} />
          <Route path="/sales-reps-managements" element={<SalesRepManagement />} />
          <Route path="/all-businesses" element={<AllBusinessesPage />} />
          <Route path="/business-details/:id" element={<BusinessDetailsPage />} />
          <Route path="/edit-business/:id" element={<UpdateBusiness />} />
          <Route path="/account" element={<ProfilePage />} />
          <Route path="/users" element={<UsersList />} />
          <Route path="/roles" element={<RolesManagement />} />
          <Route path="/logs" element={<LogsPage />} />
          <Route path="/trash" element={<TrashPage />} />
          <Route path="/documents" element={<DocumentsPage />} />
          <Route path="/supplies-categories" element={<CategoriesManagement />} />
          <Route path="/supplies-items" element={<SuppliesManagement />} />
          <Route path="/clients-orders" element={<ClientsOrders />} />
          <Route path="/clients-finished-orders" element={<ClientsFinishedOrders />} />
          <Route path="/order-details/:id" element={<OrderDetails />} />
          <Route path="/print/:id" element={<PrintOrderPage />} />


          <Route path="/warehouses" element={<Warehouse />} />
          <Route path="/labs" element={<AllLabLocationsPage />} />
          <Route path="/facilities" element={<FacilitiesPage />} />
          <Route path="/pscs" element={<PSCLocationsPage />} />
          <Route path="/insurance-categories" element={<InsuranceCategories />} />
          <Route path="/insurance" element={<Insurances />} />
          <Route path="/test-prices-categories" element={<TestsPricesCategories />} />
          <Route path="/test-prices" element={<Prices />} />

          {/* Couriers */}
          <Route path="/couriers/couriers" element={<AllCouriers />} />

          {/* Routes */}
          <Route path="/routes/routes" element={<AllRoutes />} />
          <Route path="/routes/routes-pickups" element={<AllRoutesPickups />} />
          <Route path="/routes/view/:routeId" element={<ViewRoute />} />

          {/* Pickups */}
          <Route path="/pickups" element={<AllPickups />} />
          <Route path="/pickups/finished" element={<FinishedPickups />} />
          <Route path="/pickups/request" element={<RequestPickup />} />
          <Route path="/pickups/receive" element={<ReceivePickups />} />
          <Route path="/pickups/view/:id" element={<ViewPickup />} />

          {/* Inventory */}
          <Route path="/inventory/levels/:warehouseId" element={<InventoryLevels />} />
          <Route path="/inventory/receive/:warehouseId" element={<ReceiveInventory />} />
          <Route path="/inventory/transactions/:warehouseId" element={<TransactionsHistory />} />
          <Route path="/inventory" element={<Warehouses />} />

          {/* Email Templates */}
          <Route path="/email" element={<EmailTemplates />} />
          <Route path="/email/create" element={<CreateTemplate />} />
          <Route path="/email/edit/:id" element={<EditTemplate />} />

          <Route path="/create-order-internal" element={<CreateOrderInternal />} />
          <Route path="/reports" element={<Report />} />


          {/* Messages */}
          <Route path="/messages" element={<MessagesList />} />

          {/* Shipments */}
          <Route path="/shipments" element={<ShipmentsList />} />
          <Route path="/transactions" element={<TransactionsPage />} />


        </Route>

        {/* Client Non-Protected Routes */}
        <Route element={<ClientLayout />}>
          <Route path="/complete-business/:token" element={<CompleteBusiness />} />
          <Route path="/create-order" element={<CreateOrder />} />
        </Route>

        {/* 404 Route */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}


export default App;
