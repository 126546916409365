import React, { useEffect, useState } from 'react';
import {
    Box, VStack, Heading, SimpleGrid, HStack, Icon, Stat, StatLabel, StatNumber,
    Table, Thead, Tbody, Tr, Th, Td, Spinner, Alert, AlertIcon, Text, Card, CardHeader, CardBody,
    FormControl, FormLabel, Input, Button,
    useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon
} from '@chakra-ui/react';
import AsyncSelect from 'react-select/async';
import { FaFileExcel, FaDownload, FaBuilding, FaBoxOpen, FaClock, FaSearch, FaTimes } from 'react-icons/fa';
import { fetchOrdersWidgets, fetchFacilitiesBySearch, fetchSuppliesBySearch, searchOrdersReports, fetchOrdersByBusiness } from '../../api';
import { hasPermission } from '../../Utils';
import TitleHeader from '../../components/TitleHeader';
import { Parser } from "@json2csv/plainjs";
import ExcelJS from "exceljs";
import AccessDenied from '../../components/AccessDenied';
import Pagination from '../../components/Pagination';

const ReportsPage = () => {
    const [ongoingOrders, setOngoingOrders] = useState(0);
    const [finishedOrders, setFinishedOrders] = useState(0);
    const [monthlyOrders, setMonthlyOrders] = useState(0);
    const [topItems, setTopItems] = useState([]);
    const [topFacilities, setTopFacilities] = useState([]);
    const [averageFinishingTime, setAverageFinishingTime] = useState("N/A");
    const [loadingOrders, setLoadingOrders] = useState(true);
    const [errorOrders, setErrorOrders] = useState(null);
    const [isSearching, setIsSearching] = useState(false);

    const [searchResults, setSearchResults] = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);


    const [selectedClient, setSelectedClient] = useState(null);
    const [clientOrders, setClientOrders] = useState([]);
    const [loadingClientOrders, setLoadingClientOrders] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();



    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [limit, setLimit] = useState(200); // Default to 50 per page
    const [loadingExport, setLoadingExport] = useState(false)

    const [searchParams, setSearchParams] = useState({
        clientId: '',
        startDate: '',
        endDate: '',
        itemId: ''
    });

    const loadOrdersWidgetData = async () => {
        try {
            const result = await fetchOrdersWidgets(10);
            if (result.success) {
                setOngoingOrders(result.data.ongoingOrders);
                setFinishedOrders(result.data.finishedOrders);
                setMonthlyOrders(result.data.monthlyOrders);
                setTopItems(result.data.topItems || []);
                setTopFacilities(result.data.topFacilities || []);
                setAverageFinishingTime(result.data.averageFinishingTime || "N/A");
            } else {
                setErrorOrders(result.message || 'Failed to load widget data');
            }
        } catch (err) {
            setErrorOrders('Failed to load widget data');
        }
        setLoadingOrders(false);
    };

    useEffect(() => {
        if (hasPermission("clientOrderWidget")) {
            loadOrdersWidgetData();
        }
    }, []);

    const onInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams(prevParams => ({
            ...prevParams,
            [name]: value
        }));
    };

    const clearSearch = () => {
        setSearchParams({ clientId: '', startDate: '', endDate: '', itemId: '' });
        setIsSearching(false);
    };


    // const handleSearch = async () => {
    //     setIsSearching(true);
    //     setLoadingSearch(true);
    //     try {
    //         const result = await searchOrdersReports(searchParams);
    //         if (result.success) {
    //             setSearchResults(result.data);

    //             setCurrentPage(result.currentPage);
    //             setTotalPages(result.totalPages);

    //         } else {
    //             setSearchResults([]);
    //         }
    //     } catch (error) {
    //         setSearchResults([]);
    //     }
    //     setLoadingSearch(false);
    // };


    const handleSearch = async (newPage = 1) => {
        setIsSearching(true);
        setLoadingSearch(true);

        const validPage = Number(newPage) || 1; // ✅ Ensure it's a valid number

        try {
            const result = await searchOrdersReports(searchParams, validPage, limit);

            console.log("🔹 Backend Response:", result);

            if (result.success && result.data) {
                setSearchResults(result.data.reportData || []);
                setCurrentPage(result.data.currentPage || 1);
                setTotalPages(result.data.totalPages || 1);
            } else {
                setSearchResults([]);
                setTotalPages(1);
            }
        } catch (error) {
            console.error("❌ Error in handleSearch:", error);
            setSearchResults([]);
            setTotalPages(1);
        }
        setLoadingSearch(false);
    };




    const handleClientClick = async (clientId, amount) => {
        setSelectedClient(clientId);
        setLoadingClientOrders(true);
        onOpen();

        try {
            const result = await fetchOrdersByBusiness(clientId, amount);
            if (result.success) {
                setClientOrders(result.data);
            } else {
                setClientOrders([]);
            }
        } catch (error) {
            setClientOrders([]);
        }
        setLoadingClientOrders(false);
    };


    const handleExportCSV = async () => {
        if (!searchResults.length) return;

        try {
            const csvData = searchResults.map(result => ({
                "Client Name": result.businessDetails.name || "Unknown",
                "Total Orders": result.totalOrders,
                // "Total Items Requested": result.totalItemsRequested,
                // "Total Items Sent": result.totalItemsSent,
                // "Backordered Items": result.backorderedItems
            }));
            const parser = new Parser();

            const csv = parser.parse(csvData); // ✅ Converts JSON to CSV
            const blob = new Blob([csv], { type: "text/csv" });
            const url = URL.createObjectURL(blob);

            // Create and trigger the download
            const a = document.createElement("a");
            a.href = url;
            a.download = `Report_${new Date().toISOString().split("T")[0]}.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error("Error exporting CSV:", error);
        }
    };


    const existingSheetNames = new Set();

    const sanitizeSheetName = (name) =>
        name.replace(/[*?:\\/[\]]/g, '').substring(0, 31);

    const getSafeUniqueSheetName = (name) => {
        let baseName = sanitizeSheetName(name).substring(0, 25); // Leave room for counter
        let uniqueName = baseName;
        let counter = 1;

        while (existingSheetNames.has(uniqueName)) {
            uniqueName = sanitizeSheetName(`${baseName} (${counter})`).substring(0, 31);
            counter++;
        }

        existingSheetNames.add(uniqueName);
        return uniqueName;
    };

    const handleExportExcel = async () => {
        if (!searchResults.length) return;


        setLoadingExport(true)


        const workbook = new ExcelJS.Workbook();
        const overviewSheet = workbook.addWorksheet("Overview");

        // ✅ Define Columns for the Overview Sheet
        overviewSheet.columns = [
            { header: "Client Name", key: "clientName", width: 30 },
            { header: "Total Orders", key: "totalOrders", width: 15 }
        ];

        // ✅ Extract Search Filters
        const { clientId, startDate, endDate, itemId } = searchParams || {};
        const searchedClientName = clientId
            ? searchResults.find(client => client.businessDetails._id === clientId)?.businessDetails.name || "Unknown Client"
            : "All Clients";

        const searchedItemName = itemId
            ? searchResults.find(client => client.requestedItems?.some(item => item.item._id === itemId))?.requestedItems?.find(item => item.item._id === itemId)?.item.name || "Unknown Item"
            : "All Items";

        // ✅ Add Search Filters (NO MERGING)
        const filterDetails = [
            ["Searched Client:", searchedClientName || "All Clients"],
            ["Start Date:", formatDate(startDate) || "N/A"],
            ["End Date:", formatDate(endDate) || "N/A"],
            ["Searched Item:", searchedItemName || "All Items"]
        ];

        filterDetails.forEach(([label, value], index) => {
            const row = overviewSheet.getRow(index + 1);
            row.getCell(1).value = label;
            row.getCell(2).value = value;
            row.getCell(1).font = { bold: true }; // Bold labels
            row.getCell(1).alignment = { horizontal: "left" };
        });

        // ✅ Leave an Empty Space Before the Table
        const emptyRowIndex = filterDetails.length + 2;
        overviewSheet.getRow(emptyRowIndex).getCell(1).value = ""; // Blank row for spacing

        // ✅ Add Client List Table
        const headerRowIndex = emptyRowIndex + 1;
        const headerRow = overviewSheet.getRow(headerRowIndex);

        headerRow.values = ["Client Name", "Total Orders"];
        headerRow.font = { bold: true, color: { argb: "FFFFFF" } };
        headerRow.alignment = { horizontal: "center", vertical: "middle" };
        headerRow.height = 30;

        // ✅ Apply background color only to columns A and B
        ["A", "B"].forEach(col => {
            headerRow.getCell(col).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "122841" } // Dark Gray
            };
        });

        // ✅ Populate Client List
        searchResults.forEach(client => {
            overviewSheet.addRow({
                clientName: client.businessDetails.name || "Unknown",
                totalOrders: client.totalOrders || 0
            });
        });

        // ✅ Generate Individual Client Sheets with Orders
        for (const client of searchResults) {
            const result = await fetchOrdersByBusiness(client.businessDetails._id, client.totalOrders);
            const orders = result.success ? result.data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) : [];

            // const sheetName = getUniqueSheetName(client.businessDetails.name || "Unknown Client");
            // const sheetName = sanitizeSheetName(getUniqueSheetName(client.businessDetails.name || "Unknown Client"));

            const sheetName = getSafeUniqueSheetName(client.businessDetails.name || "Unknown Client");
            const sheet = workbook.addWorksheet(sheetName);

            // ✅ Add Client Name & Total Orders as the Header
            const clientInfoRow = sheet.addRow([
                `Client: ${client.businessDetails.name || "Unknown Client"}`
            ]);
            sheet.mergeCells(`A${clientInfoRow.number}:D${clientInfoRow.number}`);


            clientInfoRow.font = {
                bold: true,
                size: 12,
                color: { argb: "FFFFFF" } // White Font Color
            };
            clientInfoRow.alignment = { horizontal: "center", vertical: "middle" };

            // Apply background color **only to columns A, B, C, and D**
            ["A", "B", "C", "D"].forEach(col => {
                clientInfoRow.getCell(col).fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "122841" } // Background Color
                };
            });

            clientInfoRow.height = 25;


            // ✅ Add Client Name & Total Orders as the Header
            const clientAddressRow = sheet.addRow([
                `${client.businessDetails.address.address1 + ", " + (client.businessDetails.address.address2 ? client.businessDetails.address.address2 + ", " : "") + client.businessDetails.address.city + ", " + client.businessDetails.address.state + ", " + client.businessDetails.address.zip}`
            ]);
            sheet.mergeCells(`A${clientAddressRow.number}:D${clientAddressRow.number}`);
            clientAddressRow.font = { bold: true, size: 12, color: { argb: "FFFFFF" } };
            clientAddressRow.alignment = { horizontal: "center", vertical: "middle" };
            ["A", "B", "C", "D"].forEach(col => {
                clientAddressRow.getCell(col).fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "122841" } // Background Color
                };
            });

            clientAddressRow.height = 25;

            // ✅ Add Search Date Range Row
            const dateRangeRow = sheet.addRow([
                `Report Period: ${formatDate(startDate) || "N/A"} - ${formatDate(endDate) || "N/A"}`
            ]);
            sheet.mergeCells(`A${dateRangeRow.number}:D${dateRangeRow.number}`);
            dateRangeRow.font = { italic: true };
            dateRangeRow.alignment = { horizontal: "center", vertical: "middle" };
            dateRangeRow.height = 20;



            // ✅ Add Search Date Range Row
            const totalOrders = sheet.addRow([
                `Total Orders: ${client.totalOrders}`
            ]);
            sheet.mergeCells(`A${totalOrders.number}:D${totalOrders.number}`);
            totalOrders.font = { italic: true };
            totalOrders.alignment = { horizontal: "center", vertical: "middle" };
            totalOrders.height = 20;

            orders.forEach(order => {
                // ✅ Merge all columns for Order Date (Order Start)
                const startRow = sheet.addRow([`Order Date: ${formatDate(order.createdAt)}`]);
                sheet.mergeCells(`A${startRow.number}:D${startRow.number}`);
                startRow.font = { bold: true };
                startRow.alignment = { horizontal: "center", vertical: "middle" };

                // startRow.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } };
                ["A", "B", "C", "D"].forEach(col => {
                    startRow.getCell(col).fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "D3D3D3" } // Background Color
                    };
                });
                startRow.height = 30;



                // ✅ Add New Row for Order Headers (BELOW Order Date)
                const orderHeaderRow = sheet.addRow([
                    "Item Name",
                    "Size",
                    "Requested Qty",
                    "Sent Qty"
                ]);
                sheet.getColumn(1).width = 37; // Item Name (≈ 190px)
                sheet.getColumn(2).width = 15; // Size
                sheet.getColumn(3).width = 15; // Requested Qty
                sheet.getColumn(4).width = 15; // Sent Qty

                orderHeaderRow.eachCell(cell => {
                    cell.fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "E0E0E0" } // Light Gray
                    };
                    cell.font = { bold: true };
                    cell.alignment = { horizontal: "center", vertical: "middle" };
                });
                orderHeaderRow.height = 20;

                // ✅ Add Order Items Below Headers
                order.requestedItems.forEach(item => {
                    sheet.addRow([
                        item.item?.name || "Unknown Item",
                        item.item?.size || "N/A",
                        item.quantityRequested,
                        item.quantitySent
                    ]);
                });

                // ✅ Merge 3 Rows for "Order End"
                const endRow = sheet.addRow([]);
                sheet.mergeCells(`A${endRow.number}:D${endRow.number + 2}`);
                endRow.height = 20;
            });
        }

        // ✅ Export to File
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `Report_${new Date().toISOString().split("T")[0]}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);


        setLoadingExport(false)
    };

    // ✅ **Helper Function for Date Formatting (MM/DD/YYYY)**
    const formatDate = (dateString) => {
        if (!dateString) return "N/A";
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };



    const handleExportMonthlyEstimate = async () => {
        if (!searchResults.length) return;

        const { clientId, startDate, endDate } = searchParams || {};
        if (!clientId) return alert("Please select a client.");

        const client = searchResults.find(client => client.businessDetails._id === clientId);
        if (!client) return alert("Client not found.");

        const result = await fetchOrdersByBusiness(clientId, client.totalOrders);
        const orders = result.success ? result.data : [];

        // ✅ Aggregate items (sum total requested & sent)
        const itemSummary = {};
        orders.forEach(order => {
            order.requestedItems.forEach(item => {
                const itemId = item.item?._id || "unknown";
                if (!itemSummary[itemId]) {
                    itemSummary[itemId] = {
                        name: item.item?.name || "Unknown Item",
                        size: item.item?.size || "N/A",
                        totalRequested: 0,
                        totalSent: 0
                    };
                }
                itemSummary[itemId].totalRequested += item.quantityRequested;
                itemSummary[itemId].totalSent += item.quantitySent;
            });
        });

        // ✅ Create Workbook & Sheet
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet(`Monthly Estimate - ${client.businessDetails.name}`);

        // ✅ Client Name Row (Top of File)
        const clientInfoRow = sheet.addRow([`Client: ${client.businessDetails.name}`]);
        sheet.mergeCells(`A${clientInfoRow.number}:D${clientInfoRow.number}`);
        clientInfoRow.font = { bold: true, size: 14 };
        clientInfoRow.alignment = { horizontal: "center", vertical: "middle" };
        clientInfoRow.height = 30;

        // ✅ Report Date Range Row
        const dateRangeRow = sheet.addRow([`Report Period: ${formatDate(startDate)} - ${formatDate(endDate)}`]);
        sheet.mergeCells(`A${dateRangeRow.number}:D${dateRangeRow.number}`);
        dateRangeRow.font = { italic: true };
        dateRangeRow.alignment = { horizontal: "center", vertical: "middle" };
        dateRangeRow.height = 20;

        // ✅ Total Orders Row (NEW!)
        const totalOrdersRow = sheet.addRow([`Total Orders: ${orders.length}`]);
        sheet.mergeCells(`A${totalOrdersRow.number}:D${totalOrdersRow.number}`);
        totalOrdersRow.font = { bold: true, size: 12 };
        totalOrdersRow.alignment = { horizontal: "center", vertical: "middle" };
        totalOrdersRow.height = 25;

        sheet.addRow([]); // Empty row for spacing

        // ✅ Add Column Headers (Above Items)
        const headerRow = sheet.addRow([
            "Item Name",
            "Size",
            "Requested Qty",
            "Sent Qty"
        ]);

        // ✅ Set Column Widths
        sheet.getColumn(1).width = 37; // Item Name (≈ 190px)
        sheet.getColumn(2).width = 15; // Size
        sheet.getColumn(3).width = 15; // Requested Qty
        sheet.getColumn(4).width = 15; // Sent Qty

        // ✅ Style Headers
        headerRow.eachCell(cell => {
            cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "404040" } }; // Dark Gray
            cell.font = { bold: true, color: { argb: "FFFFFF" } }; // White Text
            cell.alignment = { horizontal: "center", vertical: "middle" };
        });
        headerRow.height = 30;

        // ✅ Insert Item Data Below Headers
        Object.values(itemSummary).forEach(item => {
            sheet.addRow([
                item.name,
                item.size,
                item.totalRequested,
                item.totalSent
            ]);
        });

        // ✅ Export to File
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const url = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.href = url;
        a.download = `Monthly_Estimate_${client.businessDetails.name}_${new Date().toISOString().split("T")[0]}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };


    if (!hasPermission("viewReports")) {
        return (<AccessDenied msg="You don't have permission to access reports page" />)
    }

    const handlePageChange = (newPage) => {
        const parsedPage = Number(newPage); // Ensure newPage is a number

        if (isNaN(parsedPage) || parsedPage < 1 || parsedPage > totalPages) {
            console.warn("❌ Invalid Page Number:", newPage);
            return;
        }

        console.log("🔹 Changing to Page:", parsedPage);
        setCurrentPage(parsedPage);
        handleSearch(parsedPage); // ✅ Ensure we pass a number
    };

    return (
        <Box maxW="full" mx="auto" p="8" >
            <TitleHeader
                title="Reports"
            />


            {/* Search Filters */}
            <Heading size='md' mt={10}>Run your Report</Heading>
            <Box mb={4} mt={5} p={4} bg="gray.50" borderRadius="md" boxShadow="sm">
                <HStack spacing={4}>
                    <FormControl>
                        <FormLabel>Client</FormLabel>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={fetchFacilitiesBySearch}
                            defaultOptions
                            onChange={(option) => setSearchParams(prev => ({
                                ...prev,
                                clientId: option ? option.value : '',
                                clientName: option ? option.label : ''
                            }))}
                            value={searchParams.clientId ? { value: searchParams.clientId, label: searchParams.clientName } : null}
                            placeholder="Search and select a facility"
                            isClearable
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Start Date</FormLabel>
                        <Input name="startDate" type="date" value={searchParams.startDate || ''} onChange={onInputChange} />
                    </FormControl>

                    <FormControl>
                        <FormLabel>End Date</FormLabel>
                        <Input name="endDate" type="date" value={searchParams.endDate || ''} onChange={onInputChange} />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Item</FormLabel>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={fetchSuppliesBySearch}
                            defaultOptions
                            onChange={(option) => setSearchParams(prev => ({
                                ...prev,
                                itemId: option ? option.value : '',
                                itemName: option ? option.label : ''
                            }))}
                            value={searchParams.itemId ? { value: searchParams.itemId, label: searchParams.itemName } : null}
                            placeholder="Search and select an item"
                            isClearable
                        />
                    </FormControl>

                    <Button mt={7} colorScheme="blue" onClick={handleSearch}>
                        <FaSearch />
                    </Button>
                    {isSearching &&
                        <Button mt={7} colorScheme="red" onClick={clearSearch}>
                            <FaTimes />
                        </Button>
                    }
                </HStack>

                {isSearching && searchResults.length > 0 && (
                    <Box mt={4} mb={4}>

                        {hasPermission("exportReport") &&
                            <>

                                <Button isLoading={loadingExport} size={'sm'} ml={2} colorScheme="green" leftIcon={<FaDownload />} onClick={handleExportCSV}>
                                    Export Simple
                                </Button>
                                <Button isLoading={loadingExport} size={'sm'} ml={2} colorScheme="teal" leftIcon={<FaFileExcel />} onClick={handleExportExcel}>
                                    Export Detailed Report
                                </Button>
                                <Button isLoading={loadingExport} size={'sm'} ml={2} colorScheme="teal" leftIcon={<FaFileExcel />} onClick={handleExportMonthlyEstimate}>
                                    Export client count
                                </Button>

                            </>
                        }


                    </Box>
                )}

            </Box>

            {/* Show Dashboard if No Search */}
            {!isSearching && (
                <>
                    {loadingOrders && <Spinner size="xl" />}
                    {errorOrders && (
                        <Alert status="error">
                            <AlertIcon />
                            {errorOrders}
                        </Alert>
                    )}

                    {!loadingOrders && !errorOrders && (

                        <Card w={'full'}>
                            <CardHeader>
                                <Heading size='md'>Clients Orders Overview</Heading>
                            </CardHeader>
                            <CardBody>


                                <SimpleGrid columns={[1, 2]} spacing={10} >
                                    <Box p={6} borderRadius="md" boxShadow="lg" bg="white">
                                        <Heading size="md" mb={4}>
                                            <HStack>
                                                <Icon as={FaBuilding} />
                                                <Text>Most Requesting Facilities (This Month)</Text>
                                            </HStack>
                                        </Heading>
                                        {topFacilities && topFacilities.length > 0 ? (
                                            <Table variant="striped" colorScheme="gray">
                                                <Thead>
                                                    <Tr>
                                                        <Th>Facility Name</Th>
                                                        <Th isNumeric>Requested</Th>
                                                        <Th isNumeric>Sent</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {topFacilities.map((facility) => (
                                                        <Tr key={facility.facilityId}>
                                                            <Td>{facility.facilityName || 'Unnamed Facility'}</Td>
                                                            <Td isNumeric>{facility.totalRequested}</Td>
                                                            <Td isNumeric>{facility.totalSent}</Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        ) : (
                                            <Box>No facilities data for this month.</Box>
                                        )}
                                    </Box>

                                    <Box p={6} borderRadius="md" boxShadow="lg" bg="white">
                                        <Heading size="md" mb={4}>
                                            <HStack>
                                                <Icon as={FaBoxOpen} />
                                                <Text>Most Requested Items (This Month)</Text>
                                            </HStack>
                                        </Heading>
                                        {topItems && topItems.length > 0 ? (
                                            <Table variant="striped" colorScheme="gray">
                                                <Thead>
                                                    <Tr>
                                                        <Th>Item Name</Th>
                                                        <Th isNumeric>Requested</Th>
                                                        <Th isNumeric>Sent</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {topItems.map((item) => (
                                                        <Tr key={item.itemId}>
                                                            <Td>{item.itemName || 'Unnamed Item'}</Td>
                                                            <Td isNumeric>{item.totalRequested}</Td>
                                                            <Td isNumeric>{item.totalSent}</Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        ) : (
                                            <Box>No items requested this month.</Box>
                                        )}
                                    </Box>
                                </SimpleGrid>


                            </CardBody>
                        </Card>

                    )}
                </>
            )}




            {isSearching && (

                <>
                    <Box mt={6}>
                        <Heading size="md" mb={4}>Search Results</Heading>
                        {loadingSearch ? (
                            <Spinner size="lg" />
                        ) : (
                            <Table colorScheme="gray">
                                <Thead>
                                    <Tr>
                                        <Th>Client</Th>
                                        <Th>Total Orders</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {searchResults.map((result, index) => (
                                        <Tr _hover={{ background: "gray.400" }} style={{ cursor: "pointer" }} onClick={() => handleClientClick(result.businessDetails._id, result.totalOrders)} key={index}>
                                            <Td>{result.businessDetails.name || 'Unknown'}</Td>
                                            <Td isNumeric>{result.totalOrders}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        )}

                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            handlePageChange={handlePageChange}
                        />
                    </Box>

                    {/* Modal for Client Orders */}
                    <Modal isOpen={isOpen} onClose={onClose} size="xl">
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>Orders for {selectedClient}</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                {loadingClientOrders ? <Spinner size="lg" /> : (
                                    <Accordion allowToggle>
                                        {clientOrders.map(order => (
                                            <AccordionItem key={order._id}>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box flex="1" textAlign="left">
                                                            Order #{order._id} - {new Date(order.createdAt).toLocaleDateString()}
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    <Table variant="simple" size="sm">
                                                        <Thead>
                                                            <Tr>
                                                                <Th>Item</Th>
                                                                <Th>size</Th>
                                                                <Th isNumeric>Requested</Th>
                                                                <Th isNumeric>Sent</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {order.requestedItems.map(item => (
                                                                <Tr key={item.item._id}>
                                                                    <Td>{item.item.name}</Td>
                                                                    <Td>{item.item.size}</Td>
                                                                    <Td isNumeric>{item.quantityRequested}</Td>
                                                                    <Td isNumeric>{item.quantitySent}</Td>
                                                                </Tr>
                                                            ))}
                                                        </Tbody>
                                                    </Table>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                )}
                            </ModalBody>
                        </ModalContent>
                    </Modal>

                </>
            )}


        </Box>
    );
};

export default ReportsPage;
