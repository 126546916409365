import { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Button,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    FormControl,
    FormLabel,
    Center,
    Badge,
    Tooltip,
    useToast,
    Divider,
    Text,
    SimpleGrid,
    Heading,
    Flex,
    Circle,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Input
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import Pagination from '../../components/Pagination';
import TitleHeader from '../../components/TitleHeader';
import Loading from '../../components/Loading/Loading';
import { fetchFacilitiesBySearch, getAllPickups, searchPickups } from '../../api';
import AccessDenied from '../../components/AccessDenied';
import AsyncSelect from 'react-select/async';
import PopoverId from '../../components/PopoverId';
import { FaBox, FaEnvelope, FaLongArrowAltDown, FaPhotoVideo } from 'react-icons/fa';
import PickupNotes from './PickupNotes';
import ImageLightbox from '../../components/ImageLightBox';



const noReturn = ['pickupLocation', 'dropOffLocation', 'courier', 'noteText', 'folder', "cancellation"]

const getGoogleMapsLink = (address) => {
    const { address1, address2, city, state, zip } = address;
    const fullAddress = `${address1} ${address2} ${city} ${state} ${zip}`;
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(fullAddress)}`;
    return googleMapsUrl;
};


const humanReadableField = (field) => {


    const fieldMappings = {
        status: "Status",
        samplesReceived: "Samples Received",
        samplesCount: "Samples Count",
        "requirements.requestPhoto": "Request Photo",
        "requirements.hasSupplies": "Has Supplies",
        "requirements.hasDocs": "Has Documents",
        "priority": "Priority",
        "receivedBy": "Received By",
        timestamps: "Time Stamp",
        attachments: "Attachments"
    };

    return fieldMappings[field] || field.replace(/\./g, " "); // Default fallback: replace dots with spaces
};


const formatValue = (value) => {
    if (typeof value === "boolean") return value ? "Yes" : "No";
    if (value === null || value === undefined) return "N/A";
    if (typeof value === "string") return value; // Return the string as is, without JSON.stringify
    return JSON.stringify(value); // Use JSON.stringify for objects/arrays
};

const FinishedPickups = () => {
    const { isOpen: isViewModalOpen, onOpen: onOpenViewModal, onClose: onCloseViewModal } = useDisclosure();

    const toast = useToast()
    const [allPickups, setAllPickups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedPickup, setSelectedPickup] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [error, setError] = useState(null);
    const itemsPerPage = 25;
    const [searching, setSearching] = useState(false);
    const [searchParams, setSearchParams] = useState({
        pickupLocation: '',
        startDate: '',
        endDate: ''
    });


    useEffect(() => {
        refresh()
    }, []);


    const refresh = () => {
        getPickups();
    }


    const onInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams(prevParams => ({
            ...prevParams,
            [name]: value
        }));
    };



    const getPickups = async (page = 1) => {
        setLoading(true);
        setError(null);
        try {
            const response = await getAllPickups(page, itemsPerPage, ['RECEIVED', 'CANCELED']);
            if (response.success) {
                const pickups = response.data.pickups;
                setAllPickups(pickups);


                setCurrentPage(response.data.currentPage);
                setTotalPages(response.data.totalPages);
            } else {
                setError(response.error);
            }
        } catch (error) {
            setError('Failed to load routes');
        }
        setLoading(false);
    };

    const handleSearch = async (currentPage = 1) => {
        setLoading(true);
        try {
            const searchParam = {
                pickupLocationId: searchParams.clientId || null,    // Extract value from react-select
                startDate: searchParams.startDate,
                endDate: searchParams.endDate,
                page: currentPage,
                limit: itemsPerPage,
                status: ["RECEIVED", 'CANCELED']
            };

            setSearching(true)

            const response = await searchPickups(searchParam);
            if (response.success) {
                setAllPickups(response.data.pickups);
                setCurrentPage(response.data.currentPage);
                setTotalPages(response.data.totalPages);
            } else if (response.data.totalItems === 0) {
                setAllPickups([])
            } else {
                toast({
                    title: "Oops !",
                    description: response.error,
                    status: "error",
                    duration: 4000, // duration in milliseconds
                    isClosable: true,
                });

            }
        } catch (error) {
            console.error('Error performing search:', error);
        }
        setLoading(false);
    };

    const clearSearch = () => {
        setSearching(false);
        setSearchParams({
            clientId: '',
            startDate: '',
            endDate: ''
        })
        getPickups();
    };

    const handlePageChanges = (page) => {
        if (searching) {
            handleSearch(page);
        } else {
            getPickups(page);
        }
    };

    function getRequirementsIcons(requirements = {}) {
        const icons = [];


        if (requirements.hasDocs) {
            icons.push(
                <Tooltip label="This pickup has documents/envelopes" placement='top' key="docs">
                    <span><FaEnvelope size={15} /></span>
                </Tooltip>
            );
        }
        if (requirements.hasSupplies) {
            icons.push(
                <Tooltip label="This pickup has supplies" placement='top' key="supplies">
                    <span><FaBox size={15} /></span>
                </Tooltip>
            );
        }
        if (requirements.requestPhoto) {
            icons.push(
                <Tooltip label="This pickup requires a photo" placement='top' key="photo">
                    <span><FaPhotoVideo size={15} /></span>
                </Tooltip>
            );
        }


        return icons.length > 0 ? (
            <span style={{ display: 'flex', gap: '15px', alignItems: 'center', padding: 5 }}>
                {icons}
            </span>
        ) : "N/A";
    }

    return (
        <Box maxW="full" mx="auto" p="8" >
            <VStack spacing={6} align="stretch">
                <TitleHeader title={`Today's Pickups`}
                    thirdButton={true}
                    thirdButtonText={"Refresh"}
                    thirdButtonOnClick={refresh}
                    thirdButtonIcon={<FaLongArrowAltDown />}
                />



                <HStack p={2} mb={3} spacing={4} align="start">
                    <FormControl isRequired>
                        <FormLabel>Pickup Location</FormLabel>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={fetchFacilitiesBySearch}
                            defaultOptions
                            onChange={(option) => setSearchParams(prev => ({
                                ...prev,
                                clientId: option ? option.value : '',    // Store the value (ID)
                                clientName: option ? option.label : ''   // Optionally store the label to show after search
                            }))}
                            value={searchParams.clientId ? { value: searchParams.clientId, label: searchParams.clientName } : null} // Persist selection
                            placeholder="Search and select a facility"
                            closeMenuOnSelect={true}
                            isClearable
                        />


                    </FormControl>


                    <FormControl>
                        <FormLabel>Start Date</FormLabel>
                        <Input
                            name="startDate"
                            type="date"
                            value={searchParams.startDate || ''}
                            onChange={onInputChange}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>End Date</FormLabel>
                        <Input
                            name="endDate"
                            type="date"
                            value={searchParams.endDate || ''}
                            onChange={onInputChange}
                        />
                    </FormControl>


                    <VStack>
                        <Button mt={searching ? 0 : 34} colorScheme="blue" size={"sm"} onClick={() => handleSearch(1)}>
                            Search
                        </Button>

                        {searching &&
                            <Button colorScheme="red" size={"sm"} onClick={clearSearch}>
                                Clear
                            </Button>
                        }
                    </VStack>
                </HStack>

                <Divider />

                {loading ?
                    <Center mt={"20%"}>
                        <Loading />
                    </Center>
                    :
                    <>
                        {allPickups.length > 0 ? (
                            <>
                                <Table variant="simple">
                                    <Thead>
                                        <Tr>
                                            <Th>#ID</Th>
                                            <Th>Pickup</Th>
                                            <Th>Drop</Th>
                                            <Th>Courier</Th>
                                            <Th>Type</Th>
                                            <Th>RQ</Th>
                                            <Th>Route</Th>
                                            <Th>SC</Th>
                                            <Th>Created</Th>
                                            <Th>Finished</Th>
                                            <Th textAlign="right"> Actions</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {allPickups?.map((pickup, index) => (
                                            <Tr
                                                color={pickup.type === 'Supplies' ? 'white' : 'gray.900'}
                                                bg={pickup.type === 'DropOffOnly' ? "pink.100" : pickup.type === 'Supplies' ? 'purple' : 'white'}
                                                _hover={{ bg: "gray.700", color: "white" }} key={pickup._id}
                                            >
                                                <Td maxW={1}><PopoverId place='Pickup' index={index} content={pickup._id} /></Td>
                                                <Td>{pickup?.pickupLocation?.name || "N/A"}</Td>
                                                <Td>{pickup?.dropOffLocation?.name || "N/A"}</Td>
                                                <Td>{pickup.courier.name}</Td>
                                                <Td>
                                                    <Badge colorScheme={pickup.type === 'DropOffOnly' ? 'pink' : pickup.type === 'Supplies' ? 'purple' : "orange"}>
                                                        {pickup.type === 'DropOffOnly' ? "Drop Off" : pickup.type === 'Default' ? "Pickup" : pickup.type}
                                                    </Badge>
                                                </Td>

                                                <Td>
                                                    {getRequirementsIcons(pickup.requirements)}
                                                </Td>


                                                <Td>
                                                    {/* {pickup.pickupOrder} */}
                                                    <Badge colorScheme={pickup.routeId?.name ? 'red' : 'green'}>
                                                        {pickup.routeId?.name || "On Call"}
                                                    </Badge>
                                                </Td>

                                                <Td>{pickup.samplesCount}</Td>
                                                <Td><Text fontSize='sm'>{new Date(pickup.createdAt).toLocaleString()}</Text></Td>
                                                <Td>{pickup.status === 'CANCELED' ? <Badge colorScheme={'red'}>CANCELED</Badge> : <Text fontSize='sm'>{new Date(pickup.timestamps?.receivedTime).toLocaleString()}</Text>} </Td>


                                                <Td textAlign="right">
                                                    <HStack justifyContent="flex-end" spacing={4}>
                                                        <Button onClick={() => { setSelectedPickup(pickup); onOpenViewModal(); }} size="xs" colorScheme="green">
                                                            <ViewIcon mr={2} /> View
                                                        </Button>
                                                    </HStack>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table >

                                <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChanges} />
                            </>
                        ) : (
                            <AccessDenied msg="Nothing here yet !" icon='info' />
                        )}
                    </>
                }

                {selectedPickup &&


                    <Modal size={"full"} scrollBehavior='inside' isOpen={isViewModalOpen} onClose={onCloseViewModal}>
                        <ModalOverlay />
                        <ModalContent>
                            <ModalHeader>{selectedPickup?.pickupLocation?.name || selectedPickup?.dropOffLocation?.name}</ModalHeader>
                            <ModalBody>

                                <Flex p={4} borderBottom={'1px solid #eef1f6'}>
                                    {/* Left Column (25% width) */}
                                    <Box w="40%" maxHeight="550px" overflowY="auto" bg="gray.50" p={4} boxShadow="md">
                                        {/* Content for the left column goes here */}
                                        <VStack spacing={6} align="start" p={4}>
                                            {/* Timeline for pickup creation */}
                                            <HStack align="start" spacing={4}>
                                                {/* Timeline Marker */}
                                                <Flex direction="column" align="center">
                                                    <Circle size="10px" bg="blue.500" />
                                                    <Box h="100px" w="2px" bg="gray.300" />
                                                </Flex>

                                                {/* Timeline Content */}
                                                <Box>
                                                    <Text fontSize="sm" color="gray.500">
                                                        {new Date(selectedPickup.createdAt).toLocaleDateString() +
                                                            " - " +
                                                            new Date(selectedPickup.createdAt).toLocaleTimeString()}
                                                    </Text>
                                                    <Text fontSize="lg" fontWeight="bold">Pickup Created</Text>
                                                    <Text fontSize="md" color="gray.600">
                                                        Pickup was{" "}
                                                        {selectedPickup.routeId?.name ? (
                                                            <>
                                                                generated from route{" "}
                                                                <Popover>
                                                                    <PopoverTrigger>
                                                                        <Text
                                                                            as="span"
                                                                            fontWeight="bold"
                                                                            color="blue.500"
                                                                            cursor="pointer"
                                                                        >
                                                                            {selectedPickup.routeId.name}
                                                                        </Text>
                                                                    </PopoverTrigger>
                                                                    <PopoverContent>
                                                                        <PopoverArrow />
                                                                        <PopoverCloseButton />
                                                                        <PopoverHeader fontWeight="bold" fontSize="lg">
                                                                            {selectedPickup.routeId.name}
                                                                        </PopoverHeader>
                                                                        <PopoverBody>
                                                                            <VStack align="start" spacing={2}>
                                                                                <Text fontSize="sm">
                                                                                    {selectedPickup.routeId.cities}
                                                                                </Text>
                                                                            </VStack>
                                                                        </PopoverBody>
                                                                    </PopoverContent>
                                                                </Popover>
                                                            </>
                                                        ) : (
                                                            "added"
                                                        )}{" "}
                                                        By {selectedPickup?.createdBy?.firstName}
                                                    </Text>


                                                </Box>
                                            </HStack>

                                            {/* Scrollable Timeline */}
                                            <Box w="100%" pt={2}>
                                                {selectedPickup?.lastUpdated?.length > 0 &&
                                                    selectedPickup.lastUpdated.map((log, index) => (
                                                        <>
                                                            <HStack align="start" spacing={4} mt={4} pb={3} key={index}>
                                                                {/* Timeline Marker */}
                                                                <Flex direction="column" align="center">
                                                                    <Circle size="10px" bg="blue.500" />
                                                                    {index < selectedPickup.lastUpdated.length - 1 && <Box h="90px" w="2px" bg="gray.300" />}
                                                                </Flex>

                                                                {/* Timeline Content */}
                                                                <Box style={{ width: '100%' }}>
                                                                    <Text fontSize="sm" color="gray.500">
                                                                        {new Date(log.timestamp).toLocaleDateString() +
                                                                            " - " +
                                                                            new Date(log.timestamp).toLocaleTimeString()}
                                                                    </Text>
                                                                    <Text fontSize="lg" fontWeight="bold">{log.action}</Text>
                                                                    <Text fontSize="md" color="gray.600">
                                                                        Updated by {log.updatedBy?.firstName || "Unknown User"}
                                                                    </Text>
                                                                    <Box mt={2}>
                                                                        <SimpleGrid columns={3} spacing={4}>
                                                                            {log.fields.map((field, fieldIndex) => (
                                                                                noReturn.includes(field.field) ? null :
                                                                                    <Box
                                                                                        backgroundColor={formatValue(field.newValue) === 'CANCELED' ? 'red.100' : null}
                                                                                        p={formatValue(field.newValue) === 'CANCELED' ? 3 : 0}
                                                                                        borderRadius={10}

                                                                                        key={fieldIndex} mb={2}>
                                                                                        <Text fontSize="sm" color="gray.600">
                                                                                            <strong>{humanReadableField(field.field)}</strong>:
                                                                                        </Text>
                                                                                        <Text fontSize="sm" color="gray.500">
                                                                                            Previously : <strong>{formatValue(field.previousValue)}</strong>
                                                                                        </Text>
                                                                                        <Text fontSize="sm" color="gray.500">
                                                                                            Changed To : <strong>{formatValue(field.newValue)}</strong>
                                                                                        </Text>
                                                                                        {formatValue(field.newValue) === "CANCELED" &&
                                                                                            <>
                                                                                                <Text fontSize="sm" color="gray.500">
                                                                                                    Reason : <strong>{selectedPickup.cancellation.reason}</strong>
                                                                                                    <br />
                                                                                                    By : <strong>{selectedPickup.cancellation.by.firstName} at {new Date(selectedPickup.cancellation.date).toLocaleString()}</strong>
                                                                                                </Text>
                                                                                            </>
                                                                                        }


                                                                                    </Box>
                                                                            ))}
                                                                        </SimpleGrid>
                                                                    </Box>
                                                                </Box>
                                                            </HStack>
                                                            <Divider />
                                                        </>
                                                    ))}
                                            </Box>
                                        </VStack>
                                    </Box>


                                    {/* Right Column (75% width) */}
                                    <Box w="35%" p={4} borderRight={'1px solid #eef1f6'} >
                                        {/* Content for the right column goes here */}
                                        <Heading>Pickup Details</Heading>
                                        <Text mt={5}>
                                            <strong>Pickup</strong><br />
                                            <span style={{ fontWeight: "500", fontSize: 15, marginRight: 5 }}>{selectedPickup.pickupLocation.name}</span>
                                            <small>({selectedPickup.pickupLocation.address.city})  - <a href={getGoogleMapsLink(selectedPickup.pickupLocation.address)} target='_blank'>View in map</a></small>
                                        </Text>

                                        <Text mt={5}>
                                            <strong>Drop</strong><br />
                                            <span style={{ fontWeight: "500", fontSize: 15 }}>{selectedPickup.dropOffLocation.name}</span>
                                            <small> ({selectedPickup.dropOffLocation.address.city}) </small>
                                        </Text>
                                        <Box pb={5}>
                                            <Heading Heading mt={5} as='h3' size='lg'>Courier</Heading>
                                            <Text mt={5}>
                                                <strong style={{ marginRight: 10 }}>
                                                    {selectedPickup.courier.name}
                                                </strong>
                                                <Badge mr={5} colorScheme={selectedPickup.type === 'DropOffOnly' ? 'pink' : selectedPickup.type === 'Supplies' ? 'purple' : "orange"}>
                                                    {selectedPickup.type === 'DropOffOnly' ? "Drop Off" : selectedPickup.type === 'Default' ? "Pickup" : selectedPickup.type}
                                                </Badge>
                                                <Badge mr={5} colorScheme={selectedPickup.routeId?.name ? 'red' : 'green'}>
                                                    {selectedPickup.routeId?.name || "On Call"}
                                                </Badge>
                                                <Badge colorScheme={'purple'}>
                                                    Pickup Order #{selectedPickup.pickupOrder}
                                                </Badge>

                                            </Text>
                                        </Box>
                                        <Divider />
                                        <Box pb={5}>
                                            <Heading Heading mt={5} as='h3' size='lg'>Pickup Requirements</Heading>

                                            <Text mt={2}>
                                                {getRequirementsIcons(selectedPickup.requirements)}
                                            </Text>

                                        </Box>

                                        <Divider />

                                        <Box pb={5}>
                                            <Heading Heading mt={5} as='h3' size='lg'>Pickup Receiver</Heading>

                                            <Text mt={2}>
                                                Receiver : <strong>{selectedPickup?.receivedBy?.firstName}</strong><br></br>
                                                Picked Up Samples : <strong>{selectedPickup?.samplesCount}</strong><br></br>
                                                Received Samples : <strong>{selectedPickup?.samplesReceived}</strong>
                                            </Text>

                                        </Box>
                                    </Box>




                                    {/* Right Column (75% width) */}
                                    <Box w="25%" p={4}>
                                        {/* Content for the right column goes here */}
                                        <Heading>Attachements</Heading>
                                        <SimpleGrid columns={[2, 3, 8]} spacing={6} mt={6}>
                                            {selectedPickup?.attachments?.length > 0 && (
                                                selectedPickup?.attachments
                                                    .filter((attachment) => !attachment.deletedAt) // Filter out deleted attachments
                                                    .map((attachment, index) => (
                                                        <Box
                                                            key={index}
                                                            position="relative"
                                                            textAlign="center"
                                                            _hover={{ '.delete-button': { opacity: 1 } }} // Show the delete button on hover
                                                        >
                                                            {/* Image */}
                                                            <ImageLightbox
                                                                imageUrl={process.env.REACT_APP_APP_URL + attachment.fileUrl}
                                                                altText="Pickup Attachment"
                                                                boxSize="64px"
                                                                borderRadius="md"
                                                                boxShadow="md"
                                                                cursor="pointer"
                                                            />
                                                        </Box>
                                                    ))
                                            )}
                                        </SimpleGrid>
                                    </Box>

                                </Flex>

                                <PickupNotes pickupId={selectedPickup._id} existingNotes={selectedPickup.notes} addNote={false} />
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={onCloseViewModal}>Close</Button>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>

                }

            </VStack >
        </Box >
    );
};

export default FinishedPickups;
