import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormControl,
    FormLabel,
    Textarea,
    Alert,
    AlertIcon,
    AlertDescription,
    Checkbox,
    HStack,
    // Select,
    Text,
    AlertTitle,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { fetchLabLocationsOutside, fetchFacilitiesBySearch, getAllFacilitiesOutside, fetchCouriersBySearch, createPickup, updatePickup } from '../../api'; // Add necessary API functions
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { alert } from '../../Utils';
import { FaInfoCircle } from 'react-icons/fa';


const CreatePickupModal = ({ isOpen, onClose, selectedPickup = null, onCreate }) => {
    const [pickupType, setPickupType] = useState('Default');
    const [pickupLocation, setPickupLocation] = useState(selectedPickup ? selectedPickup.pickupLocation._id : null);
    const [dropOffLocation, setDropOffLocation] = useState('');
    const [note, setNote] = useState(null);
    const [facilities, setFacilities] = useState([]);
    const [labLocations, setLabLocations] = useState([]);
    const [courier, setCourier] = useState('');

    const [requestPhoto, setRequestPhoto] = useState(true);
    const [hasSupplies, setHasSupplies] = useState(false);
    const [hasDocs, setHasDocs] = useState(false);

    const [loading, setLoading] = useState(false);
    const [errorModal, setErrorModal] = useState(null);

    useEffect(() => {
        getFacilities();
        getLabLocations();
    }, []);

    const getFacilities = async () => {
        try {
            const response = await getAllFacilitiesOutside();
            if (response.success) {
                setFacilities(response.data.facilities);
            }
        } catch (error) {
            setErrorModal('Error loading facilities');
        }
    };

    const getLabLocations = async () => {
        try {
            const response = await fetchLabLocationsOutside();
            if (response.success) {
                setLabLocations(response.data);
            }
        } catch (error) {
            setErrorModal('Error loading lab locations');
        }
    };


    const handleCreatePickup = async () => {
        if (selectedPickup) {
            // Validate fields

            if (pickupType !== 'DropOffOnly' && !dropOffLocation || !courier) {
                setErrorModal('Pickup location, Drop off location and courier are required fields');
                return;
            }

            setLoading(true)


            const updatedData = {
                status: 'PENDING',
                type: pickupType,
                pickupLocation: selectedPickup.pickupLocation._id,
                dropOffLocation,
                courier: courier.value,
                requirements: {
                    hasDocs: hasDocs,
                    hasSupplies: hasSupplies,
                    requestPhoto: requestPhoto,
                },
            };

            if (note) {
                updatedData.noteText = note
            }

            const response = await updatePickup(updatedData, selectedPickup._id)
            if (response.success) {
                alert("Great", "success", "Pickup was created successfully", "Okay !");
                onClose()
            } else {
                setErrorModal(response.error);
            }
        } else {
            // Validate fields
            if (!pickupLocation || pickupType !== 'DropOffOnly' && !dropOffLocation || !courier) {
                setErrorModal('Pickup location, Drop off location and courier are required fields');
                return;
            }
            setLoading(true)

            const data = {
                type: pickupType,
                pickupLocation,
                dropOffLocation,
                courier: courier.value,
                hasDocs: hasDocs,
                hasSupplies: hasSupplies,
                requestPhoto: requestPhoto,
            }

            if (note && note.length > 0) {
                data.noteText = note
            }

            const response = await createPickup(data)
            if (response.success) {
                onCreate()
                alert("Great", "success", "Pickup was created successfully", "Okay !");


                setPickupType('Default')
                setPickupLocation(null)
                setDropOffLocation(null)
                setNote(null)
                setCourier('')
                setRequestPhoto(true)
                setHasSupplies(false)
                setHasDocs(false)


                onClose()
            } else {
                setErrorModal(response.error);
            }

        }



        setLoading(false)
    };







    return (
        <Modal scrollBehavior='inside' size={"3xl"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Pickup</ModalHeader>
                <ModalBody>
                    {errorModal && <Alert status="error"><AlertIcon /><AlertDescription>{errorModal}</AlertDescription></Alert>}

                    <FormControl mb={3} mt={3}>
                        <FormLabel>Pickup Type</FormLabel>

                        {selectedPickup ?
                            <Text fontWeight={'semibold'}>Default Pickup</Text>
                            :
                            <Select
                                options={[
                                    { value: 'Default', label: 'Default' },
                                    { value: 'Supplies', label: 'Supplies' },
                                    { value: 'DropOffOnly', label: 'Drop-Off Only' }
                                ]}
                                value={{ value: pickupType, label: pickupType }}
                                onChange={(option) => setPickupType(option.value)}
                            />
                        }
                    </FormControl>



                    <>

                        {selectedPickup ?
                            <FormControl mb={3}>
                                <FormLabel>Pickup location</FormLabel>
                                <Text fontWeight={'semibold'}>{selectedPickup.pickupLocation.name}</Text>
                            </FormControl>
                            :

                            <FormControl mb={3} isRequired>
                                <FormLabel>{pickupType != 'Default' ? "Drop Location" : "Pickup location"}</FormLabel>
                                {/* <AsyncSelect
                                    cacheOptions
                                    loadOptions={fetchFacilitiesBySearch}
                                    defaultOptions
                                    onChange={(option) => setPickupLocation(option ? option.value : '')}
                                    value={pickupLocation
                                        ? { value: pickupLocation, label: facilities.find(fac => fac._id == pickupLocation)?.name }
                                        : null}
                                    placeholder="Search and select a facility"
                                    closeMenuOnSelect={true}
                                    isClearable
                                /> */}

                                <Select
                                    options={facilities.map(fac => ({
                                        value: fac._id,
                                        label: `${fac.name} - ${fac.address.city}`
                                    }))}
                                    onChange={(option) => setPickupLocation(option ? option.value : '')}
                                    value={
                                        pickupLocation
                                            ? { value: pickupLocation, label: facilities.find(f => f._id === pickupLocation)?.name }
                                            : null
                                    }
                                    placeholder="Select a facility"
                                    isClearable
                                />


                            </FormControl>
                        }


                    </>


                    {pickupType !== 'DropOffOnly' &&

                        <FormControl mb={3} isRequired>
                            <FormLabel>{pickupType != 'Default' ? "Pickup Location" : "Drop off Location"}</FormLabel>
                            <Select
                                options={labLocations.map(lab => ({ value: lab._id, label: lab.name }))}
                                value={labLocations.find(lab => lab._id === dropOffLocation) ? { value: dropOffLocation, label: labLocations.find(lab => lab._id === dropOffLocation)?.name } : null}
                                onChange={(option) => setDropOffLocation(option.value)}
                            />
                        </FormControl>

                    }


                    <FormControl mb={3} isRequired>
                        <FormLabel>Courier</FormLabel>
                        <AsyncSelect
                            cacheOptions
                            loadOptions={fetchCouriersBySearch}
                            defaultOptions
                            onChange={(option) => setCourier(option)}
                            value={courier || null}
                            placeholder="Search and select a courier"
                            closeMenuOnSelect={true}
                            isClearable
                        />
                    </FormControl>



                    <Text color='red.400'>
                        Select courier to see their current pickup
                    </Text>








                    {/* Requirements */}
                    <FormControl mt={5} mb={5}>
                        <FormLabel>Requirements</FormLabel>
                        <HStack spacing={9} align="start">
                            <Checkbox
                                name="requestPhoto"
                                isChecked={requestPhoto}
                                onChange={() => setRequestPhoto(!requestPhoto)}
                            >
                                Request Photo
                            </Checkbox>
                            <Checkbox
                                name="hasSupplies"
                                isChecked={hasSupplies}
                                onChange={() => setHasSupplies(!hasSupplies)}
                            >
                                Has Supplies
                            </Checkbox>
                            <Checkbox
                                name="hasDocs"
                                isChecked={hasDocs}
                                onChange={() => setHasDocs(!hasDocs)}
                            >
                                Has Documents
                            </Checkbox>
                        </HStack>
                    </FormControl>

                    <FormControl mt={4} mb={3}>
                        <FormLabel>Note</FormLabel>

                        {selectedPickup &&
                            <Alert
                                mb={9}
                                status='success'
                                variant='subtle'
                                flexDirection='column'
                                alignItems='center'
                                justifyContent='center'
                                textAlign='center'
                                height='200px'
                            >
                                <FaInfoCircle size='50px' />
                                <AlertTitle mt={4} mb={1} fontSize='lg'>
                                    Note Left on this Pickup, Please Read !
                                </AlertTitle>
                                <AlertDescription maxWidth='sm'>
                                    {selectedPickup?.notes[0]?.noteText}
                                </AlertDescription>
                            </Alert>
                        }


                        <Textarea
                            placeholder="Add any notes"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose} mr={3}>Cancel</Button>
                    <Button isLoading={loading} colorScheme="blue" onClick={handleCreatePickup}>Submit</Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    );
};

export default CreatePickupModal;
